<template>
    <div class="full-height">
        <s-form @submit="bullCalfSelection" ref="form1">
            <h2 class="head font-poppins-semibold mt-3 text-primary">Bull Calf Selection</h2>
            <div class="row mt-5">
                <div class="col-lg-2">
                    <validated-date-picker format="DD-MM-YYYY"
                                           label="Selection Date" class="text-black c-input-datepicker field-required" :rules="{required:true}"
                                           v-model="model.selection_date"></validated-date-picker>
                </div>
                <div class="col-lg-2">
                    <validated-ajax-vue-select label="shed No"
                                               :url="shedOptionsURL" @input="loadBullOptions" :rules="{required:true}"
                                               class="c-input-select text-black field-required"
                                               v-model="shed_number"></validated-ajax-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select label="Animal" multiple
                                          :options="AnimalOption"
                                          class="c-input-select-multiplevalue text-black field-required" :rules="{required:true}"
                                          v-model="model.bull"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select label="Status" class="c-input-select text-black field-required" :rules="{required:true}"
                                          v-model="model.status" :options="statusOptionURL"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-input label="Remarks" class="text-black" v-model="model.remarks"></validated-input>
                </div>
                <div class="col-lg-2">
                    <div class="fl-x">
                        <div>
                            <validated-file-input ref="FileInput"
                                v-model="model.file"
                                label-text="Upload" class="w-5r mt-4"></validated-file-input>
                        </div>
                        <div>
                            <btn text="Save"
                                 :loading="loading" loading-text="Saving..." class="ml-3 mt-4 mb-6 px-4 h-3re"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </s-form>
        <s-form ref="quarantineForm" @submit="QuarantineAdd">
            <div class="row">
                <div class="col-12">
                     <h6 class="font-poppins-medium text-primary">Quarantine Details</h6>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select class="c-input-select text-black field-required"
                                               :options="selectedQurantinedBullsList" label="Selected Bulls"
                                               v-model="model1.id" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-2">
                    <validated-vue-select class="c-input-select text-black field-required" label="Quarantine Month"
                                          :options="quarantineOptions"
                                          v-model="model1.month" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-lg-8 mt-n2 fl-x-br">
                    <div class="card pl-0 pr-0 pb-0 mt--2 w-60p">
                        <div class="heading font-poppins-medium text-primary pt-2  bg-2 fl-x-cc fs-lg-0 mb-3">Due
                            for Selection
                        </div>
                        <table style="width:100%">
                            <th class="text-primary">Animal No</th>
                            <th class="text-primary">Breed</th>
                            <th class="text-primary">Age</th>
                            <th class="text-primary">Dam's Yield</th>
                            <tr v-for="(item, i) in selectedBullsList" :key="i">
                                <td>{{ item.animal_no }}</td>
                                <td v-if="item.breed">{{ item.breed.breed_name }}</td>
                                <td>{{ item.age }}</td>
                                <td>{{ item.dams_yield }}</td>
                            </tr>
                            <tr :key="i" v-if="selectedBullsList.length ===0">
                                <td colspan="4">No data Found</td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="column fl-x-br pt-7">
                <div>
                    <btn text="save" :loading="loading1" loading-text="Saving..." class="px-4"></btn>
                </div>
                <div>
                    <btn class="ml-2 text-primary" design="basic-b" type="reset" @click="BackToHome" text="Cancel"></btn>

                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import masterURLs from '../../data/masterURLs';
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'BullCalfSelection1',
    components: {},
    data () {
        return {
            shedOptionsURL: masterURLs.master.shed.vueSelect + '?animal=bull',
            BullURL: urls.calfSelection.addEdit,
            QuarantineAddURL: urls.calfSelection.quarantineDetailsSave,
            loading: false,
            loading1: false,
            BullOptionURL: urls.calfSelection.selectedBullsList,
            QuarantineOptionURL: urls.quarantine.vueSelect,
            statusOptionURL: [
                { label: 'Selected', value: 'Selected' },
                { label: 'Waiting List', value: 'Waiting List' },
                { label: 'Culling', value: 'Culling' }
            ],
            shed_number: '',
            AnimalOption: [],
            selectedBullsList: [],
            selectedQurantinedBullsList: [],
            model: {
                bull: '',
                status: '',
                remarks: '',
                selected_bulls: '',
                quarantine_month: '',
                selection_date: '',
                file: ''

            },
            model1: {
                id: '',
                month: ''
            },
            quarantineOptions: [
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
                { label: '8', value: '8' },
                { label: '9', value: '9' },
                { label: '10', value: '10' },
                { label: '11', value: '11' },
                { label: '12', value: '12' }
            ]
        };
    },
    watch: {
        shed_number (newValue, oldValue) {
            if (newValue) {
                this.model.bull = '';
                this.model.status = '';
                this.model.remarks = '';
                this.model.selected_bulls = '';
                this.model.quarantine_month = '';
                this.$refs.FileInput.text = '';
                this.model.file = '';
                this.$refs.form1.reset();
            }
        }
    },
    mounted () {
        this.loadQurantineBullOptions();
        this.loadSelectedBullOptions();
    },
    methods: {
        clearDataModel () {
            this.shed_number = '';
            this.model.bull = '';
            this.model.status = '';
            this.model.remarks = '';
            this.model.selected_bulls = '';
            this.model.quarantine_month = '';
            this.model.selection_date = '';
            this.model.file = '';
            this.$refs.FileInput.text = '';
            this.$refs.form1.reset();
        },
        clearDataModel1 () {
            this.model1.id = '';
            this.model1.month = '';
            this.$refs.quarantineForm.reset();
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        async loadBullOptions () {
            const response = await axios.get(urls.calfSelection.calfAnimalVueSelect + '?shed_number=' + this.shed_number);
            this.AnimalOption = response.data.data;
            await this.loadSelectedBullOptions();
        },
        async loadSelectedBullOptions () {
            const response = await axios.get(urls.calfSelection.calfDueForSelectionList + '?shed_number=' + this.shed_number);
            this.selectedBullsList = response.data.data;
        },
        async loadQurantineBullOptions () {
            const response = await axios.get(this.BullOptionURL);
            this.selectedQurantinedBullsList = response.data.data;
        },
        async bullCalfSelection () {
            try {
                this.loading = true;
                const response = await axios.form(this.BullURL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.clearDataModel();
                    await this.loadSelectedBullOptions();
                    await this.loadQurantineBullOptions();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async QuarantineAdd () {
            try {
                this.loading1 = true;
                const response = await axios.form(this.QuarantineAddURL, this.model1);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading1 = false;
                    await this.clearDataModel1();
                    await this.loadQurantineBullOptions();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading1 = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading1 = false;
            }
        }
    }
};
</script>

<style scoped>
btn {
    object-fit: cover;
}

table {
    background-color: #f3f3f3;
    text-align: center;
}

th {
    background-color: white;
    border: 0px;
    padding-top: 0.2rem;
    padding-bottom: 0.9rem;
    padding-left: 18px;
}

td {
    padding-left: 18px;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

table, td, tr {
    border: none;
    border-collapse: collapse;
}

table, th, tr {
    border-radius: 2px;

}

hr {
    height: 1px;
    color: #6c757d;
    background-color: #6c757d;
}

card {
    height: 155px;
}

heading {
    text-align: center;
    width: 25rem;
    height: 2rem;
}
</style>
